import { useStaticQuery, graphql } from "gatsby"

export default function useHeaderContent() {

  const data = useStaticQuery(graphql`
    {
      allContentfulHomepage {
        nodes {
          address: contactDetails {
            childMarkdownRemark {
              html
            }
          }
          email
          telephone
          instagramHandle
          menu {
            label
            internalLink
            externalLink
          }
        }
      }
    }
  `);
  return data.allContentfulHomepage.nodes[0];
}
