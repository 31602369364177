// Dependencies
import React, { useState, useCallback } from 'react';
// Hooks
import useEventListener from '../hooks/useEventListener';
// Components
import Nav, { MobileMenu } from './navigation';
// Styles
import '../css/app.scss';

const Layout = (props) => {

  // Mobile menu functionality...
  const [menuOpen, setMenuOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState(250);

  // CSS style for main page transitions
  const [transforms, setTransforms] = useState({
    transform: 'none'
  });

  // Set ref on footer...
  const footerEl = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.offsetHeight)
    }
  }, []);

  const handleScroll = e => {
    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const height = document.documentElement.offsetHeight;
    const offset = scrollTop + window.innerHeight;

    if (props.hasFooter && (offset >= (height - (footerHeight * 0.33)))) {

      const rotateBy = 1;
      const translateBy = 0.5;
      const calc = (1 - ((height - offset) / (footerHeight * 0.33))) * 5;
      const rotate = calc * rotateBy;
      const translateX = calc * translateBy;

      setTransforms({
        transform: `rotate(${rotate}deg) translateX(${translateX}%)`,
        transition: 'none',
        transformOrigin: 'bottom right'
      })

    } else if (props.hasFooter) {

      setTransforms({
        transform: 'none'
      })
    }

    // If mobile menu is open...
    !menuOpen || setMenuOpen(false)
  }

  // Set listener...
  useEventListener('scroll', handleScroll);

  return (
    <div className={menuOpen ? 'menu-is-open' : ''} style={{
      backgroundColor: props.bgColor || '#D0EFFA',
      paddingBottom: props.hasFooter ? `${footerHeight}px` : 0,
      overflowX: 'hidden'
    }}>
      <MobileMenu {...props} foregroundColour={props.fgColor || '#36A8F5'} />

      <div className="menu-transition-wrapper">
        <div className={`main-page`} style={transforms}>
          <header className="header">
            <Nav {...props} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          </header>
          {props.children}
        </div>
      </div>

      {props.hasFooter && (
        <footer className="footer" ref={footerEl} style={{
          backgroundColor: props.bgColor || '#D0EFFA',
          color: props.fgColor || '#36A8F5'
        }}>
          <Nav {...props} isFooter fgColor={props.fgColor || '#36A8F5'} />
        </footer>
      )}
    </div>
  )
}

export default Layout
