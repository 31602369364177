import React from 'react'

const Logo = ({className, fill}) => (
  <svg width="1609px" height="251px" viewBox="0 0 1609 251" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill || '#000000'}>
      <polygon id="Combined-Shape" points="163.082102 12 106.452682 149.992221 105.635581 149.992221 49.00616 12 0 12 0 200 36.4821025 200 33.7614462 64.192285 34.847901 64.192285 91.7484836 200 119.247901 200 176.150291 62.2799348 177.240362 62.2799348 174.517898 200 211 200 211 12"></polygon>
      <path d="M251.012114,184.818465 C239.667693,172.695626 234,155.78865 234,134.093906 C234,112.402793 239.893605,95.1272484 251.691659,82.2763491 C263.486098,69.4254497 278.183061,63 295.786162,63 C315.745983,63 329.627853,70.4748732 337.431774,85.4209885 L338.521576,85.4209885 L338.521576,66.2789947 L372,66.2789947 L372,200.201278 L338.521576,200.201278 L338.521576,180.579012 L337.431774,180.579012 C329.627853,195.528758 315.113428,203 293.883076,203 C276.641434,203 262.351114,196.941304 251.012114,184.818465 Z M268.205341,134.093906 C268.205341,147.218963 271.198229,157.518928 277.187621,164.993801 C283.175206,172.47049 291.248415,176.203387 301.410862,176.203387 C311.027505,176.203387 318.921791,172.512249 325.09372,165.129972 C331.260228,157.745879 334.524211,147.765462 334.891093,135.18872 L334.891093,132.999092 C334.891093,119.69429 331.802418,109.167373 325.63591,101.416527 C319.465788,93.6711279 311.390772,89.7966126 301.410862,89.7966126 C291.430952,89.7966126 283.401119,93.8545047 277.323169,101.964842 C271.241605,110.076995 268.205341,120.789104 268.205341,134.093906 Z"></path>
      <path d="M637.94259,159.522111 L659.186051,177.843211 C646.292046,194.61743 626.137251,203 598.725283,203 C576.93568,203 559.864234,196.941382 547.519987,184.816885 C535.170315,172.694203 529,155.789262 529,134.092984 C529,112.585527 535.352965,95.3592271 548.06432,82.4140838 C560.772059,69.4725717 577.295555,63 597.633,63 C617.787795,63 633.719938,68.8353002 645.433048,80.5004539 C657.144349,92.1692387 663,108.484114 663,129.445078 L663,139.289197 L564.951308,139.289197 C566.039974,151.3211 570.031148,160.480742 576.93568,166.768123 C583.832978,173.057321 592.730384,176.203735 603.62609,176.203735 C617.243461,176.203735 628.6835,170.644404 637.94259,159.522111 Z M597.633,88.4291272 C580.74782,88.4291272 570.215606,98.0935028 566.039974,117.413176 L627.866096,117.413176 C625.140814,98.0935028 615.06432,88.4291272 597.633,88.4291272 Z" ></path>
      <polygon points="687 136 825 136 825 102 687 102"></polygon>
      <path d="M848,200 L848,12 L928.547731,12 C950.937713,12 968.09007,17.149144 980.015681,27.4401745 C991.934041,37.7330194 997.900472,52.0791368 997.900472,70.4767125 C997.900472,83.9610106 994.48668,95.7107838 987.660908,105.727847 C980.833324,115.746724 971.046998,122.942462 958.309184,127.313247 L1005,200 L965.133142,200 L921.993903,131.685846 L884.587224,131.685846 L884.587224,200 L848,200 Z M925.907356,41.5123241 L883.585759,41.5123241 L883.585759,102.993611 L925.907356,102.993611 C936.826778,102.993611 945.198999,100.03621 951.025833,94.1123357 C956.850854,88.1939045 959.766084,80.4066861 959.766084,70.748866 C959.766084,61.6444247 956.80553,54.4904167 950.891674,49.2995426 C944.97238,44.1086684 936.647295,41.5123241 925.907356,41.5123241 Z" ></path>
      <path d="M1116.94293,159.522111 L1138.1861,177.843211 C1125.29046,194.61743 1105.13775,203 1077.72434,203 C1055.93503,203 1038.86563,196.941382 1026.51974,184.816885 C1014.17023,172.694203 1008,155.789262 1008,134.092984 C1008,112.585527 1014.35469,95.3592271 1027.06587,82.4140838 C1039.77163,69.4725717 1056.29671,63 1076.63388,63 C1096.7884,63 1112.72033,68.8353002 1124.43329,80.5004539 C1136.14262,92.1692387 1142,108.484114 1142,129.445078 L1142,139.289197 L1043.95082,139.289197 C1045.03947,151.3211 1049.0324,160.480742 1055.93503,166.768123 C1062.83224,173.057321 1071.73133,176.203735 1082.62508,176.203735 C1096.24408,176.203735 1107.68215,170.644404 1116.94293,159.522111 Z M1076.63388,88.4291272 C1059.74712,88.4291272 1049.21505,98.0935028 1045.03947,117.413176 L1106.86476,117.413176 C1104.14132,98.0935028 1094.06497,88.4291272 1076.63388,88.4291272 Z" ></path>
      <path d="M1319,134.243309 C1319,112.599227 1324.89361,95.3611391 1336.69347,82.5381032 C1348.4861,69.7150672 1363.27343,63.3035493 1381.06087,63.3035493 C1400.83635,63.3035493 1414.62785,70.7622154 1422.43177,85.6759244 L1423.52158,85.6759244 C1422.43177,74.0377239 1421.88778,59.1203916 1421.88778,40.9275508 L1421.88778,0 L1457,0 L1457,200.151745 L1423.52158,200.151745 L1423.52158,180.627625 L1422.43177,180.627625 C1414.62785,195.544957 1400.11343,203 1378.88308,203 C1361.64143,203 1347.34931,196.95444 1336.01211,184.857886 C1324.66769,172.761332 1319,155.891014 1319,134.243309 Z M1361.18928,165.076206 C1367.17686,172.536684 1375.24827,176.261488 1385.41433,176.261488 C1395.02916,176.261488 1402.92345,172.578353 1409.09538,165.212082 C1415.26189,157.844 1418.52587,147.885222 1418.89275,135.335749 L1418.89275,133.150869 C1418.89275,119.874914 1415.80408,109.370822 1409.63757,101.636781 C1403.46745,93.9081757 1395.39243,90.0420612 1385.41433,90.0420612 C1375.43261,90.0420612 1367.40278,94.0911549 1361.32483,102.183907 C1355.24326,110.278471 1352.207,120.967354 1352.207,134.243309 C1352.207,147.339908 1355.19989,157.61754 1361.18928,165.076206 Z"></path>
      <path d="M1511.92968,61.202885 L1542.27162,154.14958 L1543.36256,154.14958 L1573.51272,61 L1609,61.1050655 L1557.80171,205.166103 C1547.26499,235.722034 1527.47425,251 1502.04426,251 L1485.9678,251 L1485.9678,223.173959 L1494.14172,223.173959 C1509.945,223.173959 1520.47992,214.893714 1525.75009,198.345906 L1474,61.0380409 L1511.92968,61.202885 Z"></path>
      <polygon points="401 0 401 200 436.228696 200 436.228696 162.842422 453.16393 144.807881 490.577759 200 531 200 476.377115 119.94485 526.083904 66.6666667 486.207493 66.6666667 436.228696 122.13091 436.228696 0"></polygon>
      <path d="M1176.01211,184.818465 C1164.66769,172.695626 1159,155.78865 1159,134.093906 C1159,112.402793 1164.89361,95.1272484 1176.69166,82.2763491 C1188.4861,69.4254497 1203.18306,63 1220.78616,63 C1240.74598,63 1254.62785,70.4748732 1262.43177,85.4209885 L1263.52158,85.4209885 L1263.52158,66.2789947 L1297,66.2789947 L1297,200.201278 L1263.52158,200.201278 L1263.52158,180.579012 L1262.43177,180.579012 C1254.62785,195.528758 1240.11343,203 1218.88308,203 C1201.64143,203 1187.35111,196.941304 1176.01211,184.818465 Z M1193.20534,134.093906 C1193.20534,147.218963 1196.19823,157.518928 1202.18762,164.993801 C1208.17521,172.47049 1216.24841,176.203387 1226.41086,176.203387 C1236.02751,176.203387 1243.92179,172.512249 1250.09372,165.129972 C1256.26023,157.745879 1259.52421,147.765462 1259.89109,135.18872 L1259.89109,132.999092 C1259.89109,119.69429 1256.80242,109.167373 1250.63591,101.416527 C1244.46579,93.6711279 1236.39077,89.7966126 1226.41086,89.7966126 C1216.43095,89.7966126 1208.40112,93.8545047 1202.32317,101.964842 C1196.2416,110.076995 1193.20534,120.789104 1193.20534,134.093906 Z"></path>
    </g>
  </svg>
)

export default Logo
